<script setup>
    import { onMounted, ref, nextTick } from 'vue'

    import { storeToRefs } from 'pinia'
    import { useTourStore } from '@/store/tour'

    import MZBreadcrumb from '@/components-chillpainai/MZBreadcrumb.vue'

    const tourStore = useTourStore()
    const { tableFilters } = storeToRefs(tourStore)

    const datatable = ref()
    const loading = ref(false)
    const totalRecords = ref(0)
    const dataList = ref()

    const search = (filters) => {
        if (filters) {
            datatable.value.resetPage()
        }

        loading.value = true

        return new Promise(resolve => {
            dataList.value = [];
            totalRecords.value = 0;

            tourStore.index(tourStore.filters).then(data => {
                nextTick(() => {
                    dataList.value = data.tours.data
                    totalRecords.value = data.tours.total
                })

                loading.value = false

                resolve()
            })
        })
    }

    const onFilter = (event) => {
        tourStore.filters = { ...tourStore.filters, ...event }
        search()
    }

    const reset = () => {
        datatable.value.resetPage()
        tourStore.filters = {}
        search()
    }

    onMounted(() => {
        tourStore.filters = { search: '', sortField: 'id', sortOrder: 1 }
        search()
    })

</script>

<template>
    <MZBreadcrumb :items="[{ label: 'Tour' }, { label: 'Index' }]" />

    <br/>

    <div class="flex flex-wrap justify-content-center card-container">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">รายการทัวร์</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <DataTable
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                dataKey="id"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                responsiveLayout="scroll"
                                :first="tableFilters.first || 0"
                                :lazy="true"
                                :paginator="true"
                                ref="datatable"
                                :resizableColumns="true"
                                :rowHover="true"
                                :rows="tableFilters.rows || 10"
                                :rowsPerPageOptions="[10,25,50]"
                                :totalRecords="totalRecords"
                                sortField="id"
                                :sortOrder="1"
                                :value="dataList"

                                @page="onFilter($event)"
                                @sort="onFilter($event)"
                            >
                                <template #header>
                                <div class="flex justify-content-between">
                                    Tours
                                    <div class="flex">
                                        <router-link :to="{name: 'tours.create'}">
                                            <Button
                                                icon="pi pi-plus"
                                                class="px-3"
                                                label="New"
                                            />
                                        </router-link>
                                        <span class="p-input-icon-right ml-3">
                                            <i
                                                class="pi pi-times"
                                                @click="tableFilters.search = ''; search();"
                                            />
                                            <InputText
                                                v-model="tableFilters.search"
                                                placeholder="Search"
                                                type="text"
                                            />
                                        </span>
                                        <div>
                                            <Button 
                                                icon="pi pi-search" 
                                                class="p-button-rounded ml-2" 
                                                @click="search({search: tableFilters.search})" 
                                            />
                                        </div>
                                        <div>
                                            <Button 
                                                icon="pi pi-replay" 
                                                class="p-button-rounded p-button-danger ml-2" 
                                                @click="reset" 
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </template>

                                <template #empty>
                                <template v-if="loading">
                                    <ProgressBar
                                    mode="indeterminate"
                                    style="height: .2em"
                                    />
                                    <Skeleton
                                    v-for="i in (tableFilters.rows || 10)"
                                    :key="`skel-${i}`"
                                    class="mt-2"
                                    height="34px"
                                    />
                                </template>
                                <div
                                    v-else
                                    class="text-center font-bold my-3"
                                >
                                    No tours found.
                                </div>
                                </template>

                                <template #loading>
                                Loading tours data. Please wait.
                                </template>

                                <Column field="id" header="ID" :sortable="true" />

                                <Column field="code" header="CODE" :sortable="true" />

                                <Column field="title" header="Title" :sortable="true" />

                                <Column field="keywords" header="Keywords" :sortable="true" />

                                <Column field="created_at" header="Created" :sortable="true">
                                    <template #body="slotProps">
                                        {{ new Date(slotProps.data.created_at).toLocaleString() }}
                                    </template>
                                </Column>

                                <Column header="Options">
                                    <template #body="{ data }">
                                        <router-link :to="{name: 'tours.edit', params: {id: data.id}}">
                                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 my-1" />
                                        </router-link>

                                        <Button v-if="! data.active" icon="pi pi-minus" disabled="disabled" class="p-button-rounded p-button-danger mr-2 my-1" />
                                    </template>
                                </Column>

                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
